import { ColorModeScript } from '@chakra-ui/react'
import * as React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ChakraProvider } from '@chakra-ui/react'

import { App } from './App'
import reportWebVitals from './reportWebVitals'
// import * as serviceWorker from './serviceWorker'
import WalletProvider from './context/WalletProvider'
import UnreadCountProvider from './context/UnreadCountProvider'
import IsMobileViewProvider from './context/IsMobileViewProvider'
import { theme } from './theme'

ReactDOM.render(
   <React.StrictMode>
      <ColorModeScript />
      <BrowserRouter>
         <IsMobileViewProvider>
            <WalletProvider>
               <UnreadCountProvider>
                  <ChakraProvider theme={theme}>
                     <App />
                  </ChakraProvider>
               </UnreadCountProvider>
            </WalletProvider>
         </IsMobileViewProvider>
      </BrowserRouter>
   </React.StrictMode>,
   document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
